import React, { useContext, useState, useEffect } from "react";

import { Icon } from "@iconify/react";
import { useWindowSize } from "usehooks-ts";
import TopNavRightBox from "./rightBox/TopNavRightBox";
import SidebarContext from "../../store/sidebarContext";
import api from '../../services';

import classes from "./TopNav.module.scss";

function TopNav() {
  const sideOpenCtx = useContext(SidebarContext);
  const { width } = useWindowSize();
  const [customer, setCustomer] = useState<any>();

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const token = sessionStorage.getItem('@BISL:token');
        api.defaults.headers.common['x-access-token'] = token;
            
        const response = await api.get(`/api/user/${sessionStorage.getItem('@BISL:id')}`);
        setCustomer(response.data);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    };
  
    fetchCustomer();
  }, []);
  
  function openSidebarHandler() {
    sideOpenCtx.toggleSidebar();
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  return (
    <div className={classes.topNav}>
      <div className={classes.topNav_left}>
        <div
          className={classes.topNav_left_menu_icon}
          onClick={openSidebarHandler}
        >
          <Icon icon="ci:menu-alt-03" width="24" />
        </div>
        <div className={classes.topNav_left_menu} onClick={openSidebarHandler}>
          <div className="topNav_left_menu_open">
            <Icon icon="ci:menu-alt-03" width="24" />
          </div>

          <div className="topNav_left_menu_close">
            <Icon icon="eva:close-fill" width="24" />
          </div>
        </div>

      </div>
      <TopNavRightBox photoUrl={customer?.photoUrl}/>
      <br />

    </div>
  );
}

export default TopNav;
