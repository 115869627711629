import axios, { AxiosError, AxiosInstance } from 'axios';

export const setupApiClient = (): AxiosInstance => {
  const api = axios.create({
    baseURL: 'https://squid-app-soi6b.ondigitalocean.app/',
    //baseURL: 'http://localhost:8080',
  });

  api.interceptors.response.use(
    (response:any) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
          window.location.href = '/bisl/login';
      } else {
        return Promise.reject(error);
      }
    },
  );

  return api;
};
