import React, {useCallback,useState,useContext} from "react";
import { useSessionStorage } from "usehooks-ts";
import checkIcon from '../assets/images/check.svg'; 
import errorIcon from '../assets/images/error.svg'
import api from '../services';
import Toast from "../components/toast/Toast";

type TContext = {
  toggleLogin: () => void;
  toggleLogout: () => void; 
  isLogin: boolean;
  user: any;
  signUp(): void;
  logOut(): void;
  signIn(
    credentials: ISignInCredentials,
  ): Promise<{ firstAccessError?: boolean } | void>;
};


const LoginContext = React.createContext<TContext>({} as TContext);

interface IAuthState {
  token: string;
  user: string;
}

interface ISignInCredentials {
  userName: string;
  password: string;
}


export const LoginContextProvider: React.FC = (props) => {
  const [isLogin, setIsLogin] = useSessionStorage("isLogin", false);

  const definitionOfAuthenticationDataInHeader = useCallback(
    (token: string) => {
      api.defaults.headers.common = { Authorization: `bearer ${token}` };
    },
    [],
  );

  const [data, setData] = useState<IAuthState>(() => {
    const token = sessionStorage.getItem('@BISL:token');
    const user = sessionStorage.getItem('@BISL:userName');

    if (token && user) {
      definitionOfAuthenticationDataInHeader(token);

      return { token, user };
    }

    return {} as IAuthState;
  });


  function toggleLogin() {
    setIsLogin((prev) => !prev);
  }

  async function toggleLogout() {
    try {
      await logOut(); 
      
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }

  const [list, setList] = useState<any>([]);

  const showToast = useCallback(
    (type: any) => {
      let toastProperties: {
        id: number;
        title: string;
        description: string;
        backgroundColor: string;
        icon: any;
      } | null = null;

      const id = Math.floor(Math.random() * 101 + 1);
      switch (type) {
        case "success":
          toastProperties = {
            id,
            title: "",
            description: "Login efetuado com sucesso",
            backgroundColor: "#5cb85c",
            icon: checkIcon,
          };
          break;
        case "redirect":
            toastProperties = {
              id,
              title: "",
              description: "Redirecionando...",
              backgroundColor: "#5cb85c",
            icon: checkIcon,
          };
        break;
          case "error403":
            toastProperties = {
              id,
              title: "",
              description: "Invalid password",
              backgroundColor: "#5cb85c",
              icon: errorIcon,
            };
            break;
          case "error404":
            toastProperties = {
              id,
              title: "",
              description: "User not found",
              backgroundColor: "#5cb85c",
              icon: errorIcon,
            };
            break;
          case "error500":
            toastProperties = {
              id,
              title: "",
              description: "Internal server error",
              backgroundColor: "#5cb85c",
              icon: errorIcon,
            };
            break;
          case "errorDefault":
            toastProperties = {
              id,
              title: "",
              description: "Connection problem or unknown erro",
              backgroundColor: "#5cb85c",
              icon: errorIcon,
            };
            break;
        default:
          setList([]);
      }

      setList([...list, toastProperties]);
    },
    [list]
  );

  const signIn = useCallback(
    async ({ userName, password }) => {

     await api
        .post('/api/auth/signin', { userName, password })
        .then((res) => {

          sessionStorage.setItem('@BISL:email', res?.data?.email);
          sessionStorage.setItem('@BISL:userName', res?.data?.userName);
          sessionStorage.setItem('@BISL:token', res?.data?.accessToken);
          sessionStorage.setItem('@BISL:id', res?.data?.id);
          definitionOfAuthenticationDataInHeader(res?.data?.accessToken);

          if (res.status === 200) {
            setIsLogin(true)
            
            setTimeout(() => {
              showToast('success')
            }, 300);
            setTimeout(() => {
              showToast('redirect')
            }, 1300);
            setTimeout(() => {
              window.location.href = '/bisl';
            }, 2300);
          }
        })
        .catch((error) => {

          switch (true) {
            case error?.response?.status === 403:
              showToast('error403')
              break;
            case error?.response?.status === 404 && error?.response?.data?.message === 'User Not found.':
              showToast('error404')
              break;
            case error?.response?.status === 500:
              showToast('error500')
              break;
            case Boolean(error) : 
              showToast('errorDefault')
              break;
          }
        });
    },
    [
      showToast, definitionOfAuthenticationDataInHeader,setIsLogin
    ],
  );

  const logOut = useCallback( async () => {

    const token = sessionStorage.getItem('@BISL:token');
    api.defaults.headers.common['x-access-token'] = token;

   await api
      .post('/api/auth/signout')
      .then((res) => {

        if (res.status === 200) {
          const authKey: any = '';
          api.defaults.headers.common = authKey;
          sessionStorage.removeItem('@BISL:token');
          sessionStorage.removeItem('@BISL:userName');
          sessionStorage.removeItem('@BISL:email');
          setIsLogin(false); 
          window.location.href = '/bisl/login';
        }
      })
      .catch((error) => {

        switch (true) {
          case error?.response?.status === 500:
            showToast('error500')
            break;
          case Boolean(error) : 
            showToast('errorDefault')
            break;
        }
      });
  },
  [
    showToast,
    setIsLogin
  ],
);

const signUp = useCallback(() => {
  sessionStorage.removeItem('@BISL:token');
  sessionStorage.removeItem('@BISL:userName');
  sessionStorage.removeItem('@BISL:email');
  setData({} as IAuthState);
}, []);


  return (
    <>
    <Toast toastList={list} autoDelete={true} />
    <LoginContext.Provider
      value={{
        user: data.user,
        isLogin,
        signUp,
        logOut,
        signIn,
        toggleLogin,
        toggleLogout
      }}
      >
      {props.children}
    </LoginContext.Provider>
    </>
  );
};

const useLoginContext = () => {
  return useContext(LoginContext);
};

export { LoginContext, useLoginContext };
