const sidebarNav = [
  {
    link: "/",
    section: "dashboard",
    icon: "lucide:layout-dashboard", //width:"20"
    text: "Dashboard",
  },
  {
    link: "/orders",
    section: "orders",
    icon: "icon-park-outline:transaction-order",
    text: "Orders",
  },
  {
    link: "/products",
    section: "products",
    icon: "eos-icons:products",
    text: "Products",
  },

  {
    link: "/employees",
    section: "employees",
    icon: "ic:baseline-person-outline",
    text: "employees",
  },

  {
    link: "/customers",
    section: "customers",
    icon: "ph:users-bold",
    text: "Customers",
  },
  {
    link: "/analytics",
    section: "analytics",
    icon: "carbon:analytics",
    text: "Analytics",
  },
  {
    link: "/report",
    section: "Report",
    icon: "iconoir:stats-report",
    text: "report",
  },

];

export default sidebarNav;
