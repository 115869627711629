const images = {
  logo: require("../assets/images/logoBISL.png"),
  logoWhite: require("../assets/images/logoWhite.png"),
  avt: require("../assets/images/avatar.jpg"),
  logKey: require("../assets/images/Reset password-pana.svg"),
  checkIcon: require("../assets/images/checkIcon.png"),
  deleteIcon: require("../assets/images/deleteIcon.png"),
};

export default images;
