import React from "react";
import { useLoginContext } from '../store/loginContext';

import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthLayout = () => {
  const location = useLocation();
  const { isLogin } = useLoginContext();
  const token = sessionStorage.getItem('@BISL:token');

  return isLogin && token ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      replace
      state={{ from: location }} // <-- current location so login can redirect back is desired
    />
  );
};

export default AuthLayout;
